import {
	createRouter,
	createWebHashHistory
} from 'vue-router'

const routes = [
  {
    name: 'LoginAndRegister',
	icon: 'icon-home2',
	title: '登录页',
	title_en: 'Login Page',
	visible: 'false',
    path: '/login',
	meta: {title: '登录蓝海云平台'},
	component:() => import('../layout/loginlayout.vue'),
	children:[
		{
			name: 'Login',
			icon: 'icon-home2',
			title: '登录蓝海云平台',
			title_en: 'Login',
			path: '/login',
			meta: {title: '登录蓝海云平台'},
			component: () => import('../views/Login.vue')
		},
	]
  },
  {
    name: 'SystemCtl',
	icon: 'icon-home2',
	title: '系统概览',
	title_en: 'Home',
    path: '/',
	meta: {title: '系统概览'},
	component:() => import('../layout/mainlayout.vue'),
	children:[
		{
			name: 'SystemStat',
			icon: 'icon-home2',
			title: '系统概览',
			title_en: 'Home',
			path: '/',
			meta: {title: '系统概览'},
			component: () => import('../views/Home.vue')
		},
	]
  },
  {
    name: 'Settings',
	icon: 'icon-settings1',
	title: '基本设置',
	title_en: 'Settings',
	path: '/settings/about',
	meta: {title: '基本设置'},
	component:() => import('../layout/mainlayout.vue'),
	children: [
		{
			name: 'OpProfile',
			visible: 'false',
			title: '我的档案',
			title_en: 'OpProfile',
			path: '/settings/profile',
			meta: {title: '我的档案'},
			component: () => import('../views/OpProfile.vue')
		},
		{
			name: 'Profile',
			title: '基本资料',
			title_en: 'Profile',
			path: '/settings/about',
			meta: {title: '用户档案'},
			component: () => import('../views/Profile.vue')
		},
		{
			name: 'Security',
			visible: 'false',
			title: '安全设置',
			title_en: 'Security',
			path: '/settings/security',
			meta: {title: '用户档案'},
			component: () => import('../views/Security.vue')
		},
		{
			name: 'System',
			visible: 'false',
			title: '系统设置',
			title_en: 'System',
			path: '/settings/system',
			meta: {title: '系统设置'},
			component: () => import('../views/System.vue')
		},
		{
			name: 'Role',
			visible: 'false',
			title: '角色管理',
			title_en: 'Role',
			path: '/settings/role',
			meta: {title: '角色管理'},
			component: () => import('../views/Role.vue')
		},
		{
			name: 'Permission',
			title: '用户权限',
			title_en: 'Permission',
			path: '/settings/permission',
			meta: {title: '用户权限'},
			component: () => import('../views/Permission.vue')
		},
		{
			name: 'FileManager',
			title: '文件管理',
			title_en: 'File Manager',
			visible: 'false',
			path: '/settings/filemanager',
			meta: {title: '文件管理'},
			component: () => import('../views/FileManager.vue')
		},
	]
  },
  {
    name: 'Goods',
	icon: 'icon-goodsnew-l',
	title: '商品管理',
	title_en: 'Goods',
	visible: 'true',
	path:'/goods/productlist',
	meta: {title: '商品管理'},
	component:() => import('../layout/mainlayout.vue'),
	children: [
		{
			name: 'Brands',
			title: '品牌管理',
			title_en: 'Brands',
			path: '/goods/brands',
			meta: {title: '品牌管理'},
			component: () => import('../views/Brands.vue')
		},
		{
			name: 'Groups',
			title: '商品分类',
			title_en: 'Groups',
			path: '/goods/groups',
			meta: {title: '商品分类'},
			component: () => import('../views/Groups.vue')
		},
		{
			name: 'ProductList',
			title: '商品管理',
			title_en: 'Product List',
			path: '/goods/productlist',
			meta: {title: '商品管理'},
			component: () => import('../views/ProductList.vue')
		},
		{
			name: 'AddProduct',
			title: '添加商品',
			title_en: 'Add Product',
			path: '/goods/addproduct',
			meta: {title: '添加商品'},
			visible: 'false',
			component: () => import('../views/AddProduct.vue')
		},
		{
			name: 'GoodsComments',
			title: '评价管理',
			title_en: 'Comments',
			path: '/goods/comments',
			visible: 'false',
			meta: {title: '评价管理'},
			component: () => import('../views/GoodsComments.vue')
		},
	]
  },
  {
	name: 'Customers', 
	icon: 'icon-order-list1',
	title: '客户管理',
	visible: 'true',
	title_en: 'Customers',
	path:'/customers/customermanage',
	meta: {title: '客户管理'},
	component:() => import('../layout/mainlayout.vue'),
	children:[
		{
			name: 'Agentlist',
			title: '商户列表',
			title_en: 'Agentlist',
			path: '/customers/agentlist',
			meta: {title: '商户列表'},
			visible:'true',
			component: () => import('../views/Agents.vue')
		},
		{
			name: 'AddAgent',
			title: '添加商户',
			title_en: 'AddAgent',
			path: '/customers/addagent',
			meta: {title: '添加商户'},
			visible:'false',
			component: () => import('../views/AddAgent.vue')
		},
		{
			name: 'Storelist',
			title: '店铺列表',
			title_en: 'Storelist',
			path: '/customers/storelist',
			meta: {title: '商户列表'},
			visible:'true',
			component: () => import('../views/Stores.vue')
		},
		{
			name: 'AddStore',
			title: '添加店铺',
			title_en: 'AddStore',
			path: '/customers/addstore',
			meta: {title: '添加商户'},
			visible:'false',
			component: () => import('../views/AddStore.vue')
		},
	]
  },
  {
	name: 'OrderQuery', 
	icon: 'icon-order-list',
	title: '订单查询',
	visible: 'true',
	title_en: 'OrderQuery',
	path:'/orderquery/orderquery',
	meta: {title: '订单查询'},
	component:() => import('../layout/mainlayout.vue'),
	children:[
		{
			name: 'PDDOrderlist',
			title: '订单列表',
			title_en: 'PDD Order List',
			path: '/orderquery/pddorderlist',
			meta: {title: '订单列表'},
			visible:'true',
			component: () => import('../views/PDDOrderlist.vue')
		},
		{
			name: 'PDDAfter',
			title: '售后列表',
			title_en: 'PDD Order AfterSale',
			path: '/orderquery/pddafter',
			meta: {title: '售后列表'},
			visible:'true',
			component: () => import('../views/AfterSale.vue')
		}
	]

  },
	{
		name: 'Analyse',
		icon:"icon-tuwenliebiao1",
		title: '数据统计',
		visible: 'true',
		title_en: 'Analyse',
		path:'/analyse/analyse',
		meta: {title: '数据统计'},
		component:() => import('../layout/mainlayout.vue'),
		children:[
			{
				name: 'AnalyseList',
				title: '数据统计',
				title_en: 'Analyse Dialog',
				path: '/analyse/analyselist',
				meta: {title: '数据统计'},
				visible:'true',
				component: () => import('../views/AnalyseList.vue')
			},
			{
				name: 'AnalyseDialog',
				title: '统计图表',
				title_en: 'Analyse Dialog',
				path: '/analyse/analysedialog',
				meta: {title: '统计图表'},
				visible:'true',
				component: () => import('../views/AnalyseDialog.vue')
			}
		]
	},
  /*
  {
	name: 'Qrcode', 
	icon: 'icon-order-list',
	title: '吊牌管理',
	visible: 'true',
	title_en: 'Qrcode',
	path:'/antiqrcode/qrcodemanage',
	meta: {title: '吊牌管理'},
	component:() => import('../layout/mainlayout.vue'),
	children:[
		{
			name: 'Batch',
			title: '批次信息',
			title_en: 'Batch',
			path: '/antiqrcode/batchlist',
			meta: {title: '批次信息'},
			visible:'true',
			component: () => import('../views/Batch.vue')
		},
		{
			name: 'Distribute',
			title: '吊牌分配',
			title_en: 'Distribute',
			path: '/antiqrcode/distribute',
			meta: {title: '吊牌分配'},
			visible:'true',
			component: () => import('../views/Distribute.vue')
		}
	]
  },*/
  {
    name: 'Article',
	icon: 'icon-tuwenliebiao',
	title: '图文管理',
	visible: 'false',
	title_en: 'Article',
	path:'/article/articlelist',
	meta: {title: '图文管理'},
	component:() => import('../layout/mainlayout.vue'),
	children: [
		{
			name: 'Channel',
			title: '频道管理',
			title_en: 'Channel',
			visible: 'false',
			path: '/article/channel',
			meta: {title: '频道管理'},
			component: () => import('../views/Channel.vue')
		},
		{
			name: 'Category',
			title: '图文分类',
			title_en: 'Category',
			path: '/article/category',
			meta: {title: '图文分类'},
			component: () => import('../views/Category.vue')
		},
		{
			name: 'ArticleList',
			title: '图文管理',
			title_en: 'Article List',
			path: '/article/articlelist',
			meta: {title: '图文管理'},
			component: () => import('../views/ArticleList.vue')
		},
		{
			name: 'ArtDetail',
			title: '图文编辑器',
			title_en: 'Article Detail Editor',
			visible: 'false',
			path: '/article/artdetail',
			meta: {title: '图文编辑器'},
			component: () => import('../views/ArtDetail.vue')
		},
		{
			name: 'ArtComments',
			title: '评价管理',
			title_en: 'Comments',
			visible: 'false',
			path: '/article/artcomments',
			meta: {title: '评价管理'},
			component: () => import('../views/ArtComments.vue')
		},
	]
  },
  {
    name: 'Orders',
	icon: 'icon-order-list1',
	title: '订单管理',
	visible: 'false',
	title_en: 'Orders',
	path:'/orders/ordersadmin',
	meta: {title: '订单管理'},
	component:() => import('../layout/mainlayout.vue'),
	children: [
		{
			name: 'OrderList',
			title: '订单管理',
			title_en: 'Order List',
			path: '/orders/ordersadmin',
			meta: {title: '订单管理'},
			component: () => import('../views/OrderList.vue')
		},
		{
			name: 'OrderDetail',
			title: '订单详情',
			title_en: 'Order Detail',
			path: '/orders/orderdetail',
			meta: {title: '订单详情'},
			visible: 'false',
			component: () => import('../views/OrderDetail.vue')
		},
		{
			name: 'OrdersExport',
			title: '订单导出',
			title_en: 'Orders Export',
			path: '/orders/ordersexport',
			meta: {title: '订单导出'},
			component: () => import('../views/OrdersExport.vue')
		},
		{
			name: 'Shipping',
			title: '订单发货',
			title_en: 'Shipping',
			path: '/orders/shipping',
			meta: {title: '订单发货'},
			component: () => import('../views/Shipping.vue')
		},
		{
			name: 'OrderComments',
			title: '评价管理',
			title_en: 'Comments',
			path: '/orders/Ordercomments',
			meta: {title: '评价管理'},
			component: () => import('../views/OrderComments.vue')
		},
	]
  },
];

const router = createRouter({
	history: createWebHashHistory(),
	base:'/admin/',
	routes
})

export default router
