export default {
	uploadpic: "/api/index.php?entry=system&op=uploadpic",
	syslogs: "/api/index.php?entry=system&op=logs",
	homedata: "/api/index.php?entry=system&op=workbench",
	//品牌
	brandlist:'/api/index.php?entry=product&op=brandlist',
	savebrand:'/api/index.php?entry=product&op=savebrand',
	getbrand:'/api/index.php?entry=product&op=getbrand',
	getallbrand:'/api/index.php?entry=product&op=getallbrand',
	delbrand:'/api/index.php?entry=product&op=delbrand',
	enablebrand:'/api/index.php?entry=product&op=enablebrand',
	disablebrand:'/api/index.php?entry=product&op=disablebrand',
	movebrand: '/api/index.php?entry=product&op=movebrand',
	//分类
	grouplist:'/api/index.php?entry=product&op=grouplist',
	grouptree: '/api/index.php?entry=product&op=grouptree',
	savegroup:'/api/index.php?entry=product&op=savegroup',
	getgroup:'/api/index.php?entry=product&op=getgroup',
	getallgroup:'/api/index.php?entry=product&op=getallgroup',
	delgroup:'/api/index.php?entry=product&op=delgroup',
	enablegroup:'/api/index.php?entry=product&op=enablegroup',
	disablegroup:'/api/index.php?entry=product&op=disablegroup',
	cascader:'/api/index.php?entry=product&op=cascader',
	movegroup:'/api/index.php?entry=product&op=movegroup',
	groupstat:'/api/index.php?entry=product&op=groupstat',
	//文章
	saveart:"/api/index.php?entry=article&op=save",
	delart:"/api/index.php?entry=article&op=del",
	delartids: "/api/index.php?entry=article&op=delids",
	artdetail:"/api/index.php?entry=article&op=artdetail",
	artstat: "/api/index.php?entry=article&op=artstat",
	enableart:"/api/index.php?entry=article&op=enable",
	disableart:"/api/index.php?entry=article&op=disable",
	artlist:"/api/index.php?entry=article&op=artlist",
	catelist: "/api/index.php?entry=article&op=catelist",
	catestat: "/api/index.php?entry=article&op=catestat",
	catetree: "/api/index.php?entry=article&op=catetree",
	movecate: "/api/index.php?entry=article&op=movecate",
	catedetail: "/api/index.php?entry=article&op=catedetail",
	savecate: "/api/index.php?entry=article&op=savecate",
	delcate: "/api/index.php?entry=article&op=delcate",
	//账号角色
	rolelist:"/api/index.php?entry=role&op=rolelist",
	saverole:"/api/index.php?entry=role&op=saverole",
	delrole:"/api/index.php?entry=role&op=delrole",
	getpermission:"/api/index.php?entry=system&op=getpermission",
	oplist:"/api/index.php?entry=role&op=oplist",
	saveop:"/api/index.php?entry=role&op=saveop",
	editop:"/api/index.php?entry=role&op=editop",
	editrole: "/api/index.php?entry=role&op=editrole",
	oppermits: "/api/index.php?entry=role&op=oppermits",
	rolepermits: "/api/index.php?entry=role&op=rolepermits",
	oprolepermits: "/api/index.php?entry=role&op=oprolepermits",
	delop:"/api/index.php?entry=role&op=delop",
	savepass:"/api/index.php?entry=role&op=savepass",
	updatestatus:"/api/index.php?entry=role&op=updatestatus",
	loginurl:"/api/index.php?entry=system&op=login",
	getuserinfo:"/api/index.php?entry=system&op=getuserinfo",
	getopinfo:"/api/index.php?entry=system&op=getopinfo",
	saveuserinfo: "/api/index.php?entry=system&op=saveuserinfo",
	saveopinfo: "/api/index.php?entry=system&op=saveopinfo",
	checklogin: "/api/index.php?entry=system&op=checklogin",
	//商品
	pdtlist:"/api/index.php?entry=product&op=pdtlist",
	savepdt:"/api/index.php?entry=product&op=savepdt",
	delpdt:"/api/index.php?entry=product&op=delpdt",
	getpdtinfo:"/api/index.php?entry=product&op=getpdtinfo",
	updatepdtstatus:"/api/index.php?entry=product&op=updatepdtstatus",
	uploadmainimg: "/api/index.php?entry=product&op=uploadmainimg",
	delpdtids: "/api/index.php?entry=product&op=delpdtids",
	//订单
	orderlist:"/api/index.php?entry=order&op=orderlist",
	getlogistic:"/api/index.php?entry=order&op=getlogisticinfo",
	getlogcom:"/api/index.php?entry=order&op=getlogcom",
	savelog:"/api/index.php?entry=order&op=savelog",
	orderdetail:"/api/index.php?entry=order&op=orderdetail",
	aftersale:"/api/index.php?entry=order&op=aftersale",
	//商户
	saveagent:"/api/index.php?entry=customer&op=saveagent",
	getagent:"/api/index.php?entry=customer&op=getagent",
	agentlist:"/api/index.php?entry=customer&op=agentlist",
	savestore:"/api/index.php?entry=customer&op=savestore",
	getstore:"/api/index.php?entry=customer&op=getstore",
	storelist:"/api/index.php?entry=customer&op=storelist",
	getprovince:"/api/index.php?entry=system&op=getprovince",
	getcity:"/api/index.php?entry=system&op=getcity",
	getarea:"/api/index.php?entry=system&op=getarea",
	getagentlist:"/api/index.php?entry=customer&op=getagentlist",
	delstore:"/api/index.php?entry=customer&op=delstore",
	delagent:"/api/index.php?entry=customer&op=delagent",
	pddorderlist:"/api/index.php?entry=customer&op=pddorderlist",
	authorize:"/api/index.php?entry=customer&op=authorize",
	getstorelist:"/api/index.php?entry=customer&op=getstorelist",
	getstatis:"/api/index.php?entry=customer&op=getstatis",
	getpddorder:"/api/index.php?entry=customer&op=getpddorder",
	pddafter:"/api/index.php?entry=customer&op=pddafter",
	symcorder:"/api/index.php?entry=customer&op=symcorder",
	//数据统计
	statlist:"/api/index.php?entry=statistic&op=statlist",
	statdialog:"/api/index.php?entry=statistic&op=statdialog",
}