import {
	createApp
} from 'vue';

import ElementPlus from 'element-plus';
import App from './App.vue'
import router from './router'
import 'element-plus/theme-chalk/index.css';
import 'element-plus/theme-chalk/display.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import axios from 'axios';
import VueAxios from 'vue-axios'
import actions from './actions.js'

import * as ElIconModules from '@element-plus/icons-vue'

import {
	getCookie
} from './util/index.js';


const LANGUAGES = {
	en: require('@/assets/local/en-us.json'),
	chs: require('@/assets/local/zh-cn.json')
};

let VueApp = createApp(App);

VueApp.config.globalProperties.LANGUAGES = LANGUAGES;
VueApp.config.globalProperties.LANG = LANGUAGES['chs'];
VueApp.config.globalProperties.actions = actions;
VueApp.config.globalProperties.apibase = '/api/'

let selicons = ['Refresh', 'DocumentAdd', 'Delete', 'Plus', 'Grid', 'Fold', 'User', 'Lock', 'Back', 'Expand'];

for (const index in selicons) {
	const item = ElIconModules[selicons[index]]
	VueApp.component(selicons[index], item)
}

VueApp.use(router).use(ElementPlus, {
	locale: zhCn,
});

axios.interceptors.request.use(function(config) {
	let logintoken = getCookie('logintoken');
	let loginopid = getCookie('loginopid');

	if (logintoken && loginopid) {
		config.headers.common['logintoken'] = logintoken;
		config.headers.common['loginopid'] = loginopid;
	}

	return config;
}, function(error) {
	console.info("error: ");
	console.info(error);
	return Promise.reject(error);
});

axios.interceptors.response.use(function(response) {
	if (response.data && response.data.retcode == 7777) {
		router.push('/login');
	}
	return response;
}, function(error) {
	console.log(error);
});

VueApp.use(VueAxios, axios);
VueApp.mount('#app')
